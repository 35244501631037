/* About */
/*************************************************/

.about {
    width: 90%;
    padding-top: 3%;
    margin: 0 auto;
}

.about h2 {
    font-size: 2.5em;
    margin-bottom: 2%;
    text-align: center;
}

.about hr {
    border: none;
    height: 2px;
    background: linear-gradient(to right, transparent, #EEE, transparent);
    margin-bottom: 3%;
}

.about-container {
    margin-top: 5%;
    display: grid;
    grid-template-columns: 1fr;
    gap: 2%;
}

.about-container img {
    border: 2px solid #DDD;
    align-self: center;
    justify-self: center;
    width: 100%;
    max-width: 500px;
}

/* Media Queries for larger screens */
@media (min-width: 768px) {
    .about-container {
        grid-template-columns: repeat(2, 1fr);
    }
}