.contact {
    padding-top: 3%;
    margin-left: 2vw;
    margin-right: 2vw;
}

.contact-container {
    padding: 3%;
    display: flex;
    flex-direction: column;
    width: 70%;
}

.contact-container p {
    margin-left: 2%;
}

.contact {
    padding: 5vh 2vw;
    border-radius: 1rem;
    box-shadow: 0 1vh 3vh rgba(0, 0, 0, 0);
    color: #eee;
}

.contact h2 {
    font-size: 2.5rem;
    margin-bottom: 2vh;
    text-align: center;
}

.contact hr {
    border: none;
    height: 0.2vh;
    background: linear-gradient(to right, transparent, #EEE, transparent);
    margin-bottom: 3vh;
}

.contact-container {
    display: flex;
    flex-direction: column;
    gap: 2vh;
    max-width: 50vw;
    margin: 0 auto;
}

.contact-item {
    display: flex;
    align-items: center;
    padding: 1.5vh;
    border-radius: 0.8rem;
    transition: all 0.3s ease;
    color: #eee;
    text-decoration: none;
}

.contact-item:hover {
    background: rgba(221, 136, 0, 0.2);
    transform: translateY(-0.3vh);
}

.contact-item svg {
    font-size: 2rem;
    margin-right: 1vw;
    color: #DD8800;
}

.contact-item span {
    font-size: 1.5rem;
}

@media (max-width: 600px) {
    .contact-container {
        width: 100%;
    }

    .contact h2 {
        font-size: 1.5rem;
    }

    .contact hr {
        margin-bottom: 1.5vh;
    }

    .contact-item {
        padding: 1.5vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        color: #fff;
        border-radius: 0.5rem;
        transition: background-color 0.3s ease, transform 0.3s ease;
        width: 90%;
        margin: 0 auto;
    }

    .contact-item:hover {
        transform: translateY(-2px);
    }

    .contact-item svg {
        font-size: 1.5rem;
        margin-bottom: 0.5vh;
    }

    .contact-item span {
        font-size: 1.2rem;
    }
}