/* Home Screen */
/*************************************************/

.home {
    margin: 0;
    padding: 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    font-size: clamp(0.5em, 2em, 2em);
    font-weight: bold;

    width: clamp(100px, 100vw, 95vw);
    height: clamp(100px, 100vh, 100vh);

    width: 90%;
    margin: 0 auto;
}

/* Title */
.home h2 {
    font-size: clamp(1.5em, 5vw, 5em);
    margin-bottom: 20px;
    text-align: center;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .home h2 {
        font-size: clamp(1.2em, 4vw, 2em);
    }
}

@media (max-width: 480px) {
    .home h2 {
        font-size: clamp(1em, 5vw, 1.5em);
    }
}

.home-container {
    display: flex;
    align-items: flex-start;
    font-size: 3vw;
    height: 16*3vw;
}


/* I am a */
.home-container span {
    margin-right: 0;
}

.home-container ul {
    margin: 0;
    padding-left: 1vw;
    overflow: hidden;
    height: 5vw;
}

/* Sentence */
.home-container ul li {
    animation: slide 80s steps(1) infinite;
    position: relative;

    height: 5vw;
    margin-block: 0;
    padding-block: 0;
}

.home-container ul li span::after {
    content: "";
    position: absolute;
    left: 0;
    height: 117%;
    width: 100%;

    border-left: 2px solid;
    
    animation: typing 5s steps(15) infinite;
    animation-timing-function: 10s;

    background: linear-gradient(135deg, #222, #333);
    background-attachment: fixed;
    background-clip: content-box;


}

.subtitle {
    font-size: clamp(0.5em, 2em, 2em);
    font-weight: bold;
}

.orange {
    color: var(--secondary-color);
    font-weight: bold;
}

/* Animations */
/* Animations */
@keyframes slide {
    0% {
        top: 0;
    }
    6.25% {
        top: -5vw;
    }
    12.5% {
        top: -10vw;
    }
    18.75% {
        top: -15vw;
    }
    25% {
        top: -20vw;
    }
    31.25% {
        top: -25vw;
    }
    37.5% {
        top: -30vw;
    }
    43.75% {
        top: -35vw;
    }
    50% {
        top: -40vw;
    }
    56.25% {
        top: -45vw;
    }
    62.5% {
        top: -50vw;
    }
    68.75% {
        top: -55vw;
    }
    75% {
        top: -60vw;
    }
    81.25% {
        top: -65vw;
    }
    87.5% {
        top: -70vw;
    }
    93.75% {
        top: -75vw;
    }
    100% {
        top: -80vw;
    }
}

@keyframes typing {
    10% {
        left: 100%;
        margin: 0 -35px 0 35px;
    }

    10.1% {
        left: 100%;
        margin: 0 -350px 0 500px;
    }

    94% {
        left: 100%;
        margin: 0 -350px 0 500px;
    }

    95% {
        left: 100%;
        margin: 0 -35px 0 35px;
    }

    100% {
        left: 0;
        margin: 0 -35px 0 28px;
    }
}