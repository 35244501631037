:root {
  --primary-color: #DD8800AA;
  --secondary-color: #DD8800;
  --tertiary-color: #222;
  --background-color: #222;
  --background-gradient-start: #222;
  --background-gradient-end: #333;
  --text-color: #eee;
  --text-color-bold: #fff;
}

html {
  scroll-behavior: smooth;
}

html, body {
  overflow-x: hidden;
  position: relative;
  background-color: var(--background-color);
  color: var(--text-color-bold);
  font-family: 'Roboto', sans-serif;
  line-height: 1.6;

  height: 100vh;
  width: 100vw;
  
  overflow: hidden;
  background: linear-gradient(135deg, var(--background-gradient-start), var(--background-gradient-end));
  background-attachment: fixed;
}

body::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, var(--background-gradient-start), var(--background-gradient-end));
  z-index: -1; /* Ensure it is behind all other content */
}

h1, h2, h3 {
  font-weight: 700;
  letter-spacing: 1px;
}

li {
  list-style-type: none;
}

a {
  color: var(--text-color);
  text-decoration: none;
  font-weight: bold;
}

p {
  text-align: justify;
  text-justify: inter-word;
  font-size: clamp(11px, 22px, 22px);
}

section {
  height: 100vh;
  scroll-snap-align: center;
  overflow-x: hidden;
}

.App {
  overflow-y: scroll;
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
  height: 100vh;
}

/* Responsive Styles */
@media (max-width: 1200px) {
  html, body {
    padding: 15px;
  }

  p {
    font-size: clamp(10px, 20px, 20px);
  }
}

@media (max-width: 768px) {
  html, body {
    padding: 10px;
  }

  p {
    font-size: clamp(9px, 18px, 18px);
  }
}

@media (max-width: 480px) {
  html, body {
    padding: 5px;
  }

  p {
    font-size: clamp(8px, 16px, 16px);
  }
}



/* Navigation bar */
/*************************************************/
/* Base styles for .navbar */
.navbar {
  display: flex;
  flex-direction: row;
  gap: 50px;

  position: fixed;
  top: 0;
  z-index: 1;
  background-color: #2220;
}

/* Styles for .navbar links */
.navbar li a {
  padding: 5px;
  background-color: #2222;
}

.navbar li a:hover {
  background-color: #DD8800AA;
  color: #FFF;
}

/* Media query to hide .navbar on smaller screens */
@media (max-width: 600px) {
  .navbar {
    display: none;
  }
}
