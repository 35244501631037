.technologies {
    width: 90%;
    margin: 0 auto;
}

.technologies h2 {
    color: #EEE;
    font-size: 2.5rem; /* Changed from em to rem for better scalability */
    margin-bottom: 1.25rem; /* Changed from px to rem */
    text-align: center;
    text-shadow: 0.125rem 0.125rem 0.25rem rgba(0, 0, 0, 0.3); /* Changed from px to rem */
}

.technologies hr {
    border: none;
    height: 0.125rem; /* Changed from px to rem */
    background: linear-gradient(to right, transparent, #EEE, transparent);
    margin-bottom: 0.625rem; /* Changed from px to rem */
}

.tech-category {
    margin-bottom: 0.625rem; /* Changed from px to rem */
}

.tech-category h3 {
    color: #EEE;
    font-size: 1.2rem; /* Changed from em to rem */
    margin-bottom: 0.625rem; /* Changed from px to rem */
    text-align: center;
    text-shadow: 0.0625rem 0.0625rem 0.125rem rgba(0, 0, 0, 0.3); /* Changed from px to rem */
}

.skills-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(9.375rem, 1fr)); /* Changed from px to rem */
    gap: 1.25rem; /* Changed from px to rem */
}

.skill {
    background: rgba(221, 136, 0, 0.1);
    border-radius: 0.9375rem; /* Changed from px to rem */
    padding: 1.25rem; /* Changed from px to rem */
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    box-shadow: 0 0.3125rem 0.9375rem rgba(0, 0, 0, 0.1); /* Changed from px to rem */
    position: relative;
    overflow: hidden;
}

.skill::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: radial-gradient(circle, rgba(221, 136, 0, 0.2) 0%, transparent 70%);
    transform: rotate(45deg);
    transition: all 0.5s ease;
}

.skill:hover {
    transform: translateY(-0.625rem) scale(1.05);
    box-shadow: 0 0.9375rem 1.875rem rgba(221, 136, 0, 0.2);
}

.skill:hover::before {
    top: -75%;
    left: -75%;
}

.skill-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
}

.skill-info svg {
    color: #DD8800;
    font-size: 2.5rem;
    margin-bottom: 0.9375rem; 
    transition: all 0.3s ease;
}

.skill:hover .skill-info svg {
    transform: scale(1.2);
}

.skill-info span {
    color: #eee;
    font-size: 1.1rem; /* Changed from em to rem */
    font-weight: bold;
    transition: all 0.3s ease;
}

.skill:hover .skill-info span {
    color: #DD8800;
}

@media (max-width: 768px) {


    .technologies h2 {
        font-size: clamp(1.5em, 5vw, 5em);
        margin-bottom: 20px;
        text-align: center;
    }

    .tech-category h3 {
        font-size: 1rem; /* Reduced font size */
    }

    .skills-container {
        grid-template-columns: repeat(auto-fit, minmax(5rem, 1fr)); /* Reduced min width */
        gap: 0.5rem; /* Reduced gap */
    }

    .skill {
        padding: 0.5rem; /* Reduced padding */
    }

    .skill-info svg {
        font-size: 1.5rem; /* Reduced font size */
    }

    .skill-info span {
        font-size: 0.6rem; /* Reduced font size */
    }
}