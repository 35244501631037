.education {
    padding-top: 3%;
    margin-left: 2rem; 
}

.education h2 {
    font-size: 2.5em;
    margin-bottom: 20px;
    text-align: center;
}

.education hr {
    border: none;
    height: 0.125rem;
    background: linear-gradient(to right, transparent, #EEE, transparent);
    margin-bottom: 1.875rem;
}

.education-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.875rem;
    margin: 0 auto;
    overflow: hidden; /* Prevent scrolling */
}

.education-item {
    display: flex;
    align-items: center;
    transition: all 0.3s ease;
    gap: 1.25rem;
}

.education-item:hover {
    transform: translateY(-0.3125rem);
}

.education-item img {
    width: 30vw; 
    height: auto;
    border-radius: 0.625rem;
    border: 0.125rem solid #fff; 
    object-fit: cover;
}

.education-details {
    padding: 1.25rem;
    flex-grow: 1;
}

.education-details h3 {
    color: #DD8800;
    font-size: 1.5em;
    margin-bottom: 0.625rem;
}

.education-details p {
    display: flex;
    align-items: center;
    margin-bottom: 0.3125rem;
    color: #eee;
}

.education-details svg {
    color: #DD8800;
    margin-right: 0.625rem;
}
/* Media Queries for responsiveness */
@media (max-width: 768px) {
    .education {
        margin-left: 1rem;
    }

    .education h2 {
        font-size: 2em;
    }

    .education hr {
        margin-bottom: 1.25rem;
    }

    .education-container {
        gap: 1.25rem;
    }

    .education-item {
        flex-direction: column;
        gap: 0.625rem;
    }

    .education-item img {
        width: 70vw; /* Adjust width for smaller screens */
    }

    .education-details {
        padding: 1rem;
    }

    .education-details h3 {
        font-size: 1.5em; /* Increase font size for better readability */
        margin-bottom: 0.75rem;
    }

    .education-details p {
        font-size: 1.1em; /* Increase font size for better readability */
        line-height: 1.5; /* Improve line spacing */
    }
}

@media (max-width: 480px) {
    .education {
        margin-left: 0.5rem;
    }

    .education h2 {
        font-size: 1.75em;
    }

    .education hr {
        margin-bottom: 0.75rem; /* Reduce margin-bottom */
    }

    .education-container {
        gap: 0.75rem; /* Reduce gap between items */
    }

    .education-item {
        gap: 0.5rem;
    }

    .education-item img {
        width: 70vw; /* Make images slightly smaller */
    }

    .education-details {
        padding: 0.5rem; /* Reduce padding */
    }

    .education-details h3 {
        font-size: 1.25em; /* Maintain font size for readability */
        margin-bottom: 0.5rem; /* Reduce margin-bottom */
    }

    .education-details p {
        font-size: 1em; /* Maintain font size for readability */
        line-height: 1.4; /* Slightly reduce line spacing */
    }
}