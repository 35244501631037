:root {
    --background-color: #ffffff;

    --button-background-color: #000000;
    --button-blog-text-color: #ffffff;

    --subtitle-color: #757575;
    --blog-text-color: #000000;

    --dark-background-color: #222222;

    --dark-button-background-color: #ffffff;
    --dark-button-blog-text-color: #000000;

    --dark-card-background-color: #222222;
    --dark-card-border-color: #222222;
    --dark-card-box-shadow: #222222 --dark-subtitle-color: #b0b0b0;
    --dark-blog-text-color: #ffffff;

    --highlight-background-color: #ffeb3b;
    --highlight-text-color: #000000;
    --dark-highlight-background-color: #ff9900b9;
    --dark-highlight-text-color: #000000;

    --code-highlight-color: #f3f3f3;
    --code-highlight-text-color: #000000;

}


::selection {
    background-color: var(--highlight-background-color);
    color: ffffff var(--highlight-text-color);
}

.BlogContainer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--background-color);
    overflow: auto;
}


body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    padding: 0;
}

.MuiAppBar-root {
    background-color: var(--background-color) !important;
    color: var(--blog-text-color) !important;
    
}

.MuiToolbar-root {
    justify-content: space-between;
    box-shadow: none !important;

    
}

.MuiButton-root {
    text-transform: none !important;
    font-weight: 400 !important;
    color: var(--blog-text-color) !important;
}

.MuiButton-contained {
    background-color: var(--button-background-color) !important;
    color: var(--button-blog-text-color) !important;
}

.MuiCard-root {
    box-shadow: none !important;
    margin-bottom: 24px;
}


.MuiCard-root:hover {
    transform: translateY(-5px);
}

.post-content:hover {
    transform: translateY(0px) !important;
}



.MuiCardContent-root {
    padding: 24px !important;
}


.MuiTypography-h5 {
    font-size: 1.5rem !important;
    font-weight: 600 !important;
    margin-bottom: 8px !important;
}

.MuiTypography-subtitle2 {
    font-size: 0.875rem !important;
    color: var(--subtitle-color) !important;
    margin-bottom: 16px !important;
}

.MuiTypography-body1 {
    font-size: 1rem !important;
    line-height: 1.5 !important;
}

/* Dark mode styles */

[data-theme="dark"] {
    background-color: var(--dark-background-color);
    color: var(--dark-blog-text-color) !important;

}

[data-theme="dark"] ::selection {
    background-color: var(--dark-highlight-background-color);
    color: ffffff var(--dark-highlight-text-color);
}

[data-theme="dark"] .BlogContainer {
    width: 100%;
    height: 100%;
    background-color: var(--dark-background-color);
    color: var(--dark-blog-text-color);
}

[data-theme="dark"] .MuiAppBar-root {
    color: var(--dark-blog-text-color) !important;
    background-color: #0c0c0c !important;
}

[data-theme="dark"] .MuiButton-root {
    color: var(--dark-blog-text-color) !important;
}

[data-theme="dark"] .MuiButton-contained {
    background-color: var(--dark-button-background-color) !important;
    color: var(--dark-button-blog-text-color) !important;
}

[data-theme="dark"] .MuiCard-root {
    background-color: var(--dark-card-background-color);
    border-color: var(--dark-card-border-color);
}

[data-theme="dark"] .post-content > * {
    background-color: var(--dark-card-background-color) !important;
}

[data-theme="dark"] .MuiTypography-root {
    color: var(--dark-blog-text-color) !important;
}

[data-theme="dark"] .MuiTypography-subtitle2 {
    color: var(--dark-subtitle-color) !important;
}


/* Responsive styles */
@media (max-width: 600px) {
    .MuiToolbar-root {
        flex-direction: column;
        align-items: flex-start;
        padding: 16px;
    }

    .MuiButton-root {
        margin-top: 8px;
    }

    .MuiTypography-h5 {
        font-size: 1.25rem !important;
    }

    .MuiTypography-body1 {
        font-size: 0.875rem !important;
    }
}




.code-block pre {
    position: relative !important;
    border-radius: 5px !important;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5) !important;
    background-color: #111 !important;

}

.code-block pre::selection {
    background-color: var(--code-highlight-color) !important;
    color: var(--code-highlight-text-color) !important;
}

.copy-button {

    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 1;
    color: white !important;
    /* transparent background */
}

/* Animation */
