.projects {
    width: 90%;
    height: 80vh;
    margin: 0 auto;
}

.carousel-container {
    width: 100%;
    height: 80vh;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.carousel {
    width: 100%;
    height: 100%;
    transition: transform 0.5s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
}

.carousel-button {
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0);
    color: #eee;
    border: none;
    padding: 1em 1.25em;
    font-size: 1.5rem;
    cursor: pointer;
    transition: background 0.3s ease, color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
    border-radius: 5%;
    z-index: 10;
}

.carousel-button:hover {
    color: #fff;
    transform: translateY(-50%) scale(1.1);
    box-shadow: 0 0.5em 1em rgba(0, 0, 0, 0.3);
}

.carousel-button:active {
    animation: press 0.2s ease;
}

.carousel-button.prev {
    left: 1em;
}

.carousel-button.next {
    right: 1em;
}

@keyframes press {
    0% {
        transform: translateY(-50%) scale(1);
    }

    50% {
        transform: translateY(-50%) scale(0.9);
    }

    100% {
        transform: translateY(-50%) scale(1);
    }
}

@media (max-width: 480px) {
    .carousel-container {
        height: 70vh;
        flex-direction: column;
        justify-content: flex-start;
        position: relative;

    }

    .carousel {
        order: 1;
        width: 100%;
        height: 80%;
    }

    .carousel-button {
        width: auto;
        padding: 1em 1.5em;
        font-size: 1.5rem;
        position: absolute;
        height: 20%;


        top: 65vh;
        border-radius: 5px;
        box-shadow: 0 0.5em 1em rgba(0, 0, 0, 0.1);
        background-color: #333;

    }

    .carousel-button.prev {
        left: 1em;
    }

    .carousel-button.next {
        right: 1em;
    }
}

/* OTHERS */
/****************************************************/
.language-icon {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background: rgba(36, 36, 36, 0.5);
    border-radius: 50%;
    padding: 5px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #DD8800;
    filter: brightness(100%);
}

.language-icon img {
    width: 30px;
    height: 30px;
}

.projects h2 {
    font-size: 2.5em;
    margin-bottom: 20px;
    text-align: center;
}

.projects hr {
    border: none;
    height: 2px;
    background: linear-gradient(to right, transparent, #EEE, transparent);
    margin-bottom: 30px;
}

.card {
    width: 350px;
    height: 450px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    margin: 20px;
    text-align: center;
    transition: transform 0.3s, box-shadow 0.3s;
    background-color: #2A2A2A;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 0;
}

.card-image {
    width: 100%;
    height: 200px;
    object-fit: scale-down;
    transition: filter 0.3s;
    flex-shrink: 0;
    position: absolute;
}

.card-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(50%);
}

.card-title {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0.625rem;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    text-align: center;
    font-size: 1.2rem;
    transition: background 0.3s;
}


.card-content {
    padding: 1.25rem;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.card:hover {
    transform: translateY(-15px);
    box-shadow: 0 12px 16px rgba(0, 0, 0, 0.2);

}

.card:hover .card-image img {
    transition: background-color 1.5s;
    filter: brightness(100%);
}

.card:hover h3 {
    text-shadow:
        2px 2px 0 #222,
        -2px 2px 0 #222,
        2px -2px 0 #222,
        -2px -2px 0 #222,
        2px 0 0 #222,
        -2px 0 0 #222,
        0 2px 0 #222,
        0 -2px 0 #222;

    transition: background-color 1.5s;
}



.card h3 {
    margin-top: 0;
    color: #DD8800;
    font-size: 1.5rem;
    font-weight: bold;

    position: relative;
    top: 20%;
    transform: translateY(-50%);
    height: 9.375rem;
    max-height: 9.375rem;

    text-shadow:
        1px 1px 0 #222,
        -1px 1px 0 #222,
        1px -1px 0 #222,
        -1px -1px 0 #222;
}

.card-description {
    color: #eee;
    font-size: 1rem;
    line-height: 1.6;
    padding: 0.3125rem;

    display: flex;
    align-self: flex-start;
    justify-self: start;
}

.card-code {
    font-size: 0.875rem;
    padding: 0.5rem 0.75rem;
    font-weight: bold;
    border-radius: 0.3125rem;
    text-align: center;
    transition: transform 0.3s, box-shadow 0.3s;

    height: 3.125rem;
}

.card-code p {
    color: #eee;
    font-size: 1rem;
    line-height: 1.6;
    padding: 0.3125rem;
    margin-top: 0.3125rem;
}

.card-code.available {
    background-color: #DD8800;
    color: #222;
}

.card-code:not(.available) {
    background-color: #444;
    color: #eee;
}

.card-code:hover {
    transform: translateY(-0.1875rem);
    box-shadow: 0 0.3125rem 0.625rem rgba(221, 136, 0, 0.3);
}